import React,{useState} from 'react'
import TeamCard from "../TeamCard"
import Lottie from 'lottie-react';



// Images
import teamSlide from "../assets/team_file.svg"
import loadingGif from "../assets/loading_anim.json"

document.title = "Team";

export default function Team() {
    const teamMembers = [
        {
          id: 1,
          name: "Aminul Islam",
          position: "Founder 🧢Tech",
          imageUrl: "../assets/teamMemberImages/Animu_Islam.png"
        },
        {
          id: 2,
          name: "Kazi Musabber",
          position: "Co-founder 🧢Tech",
          imageUrl: "../assets/teamMemberImages/Kazi_Musabber.png"
        },
        {
          id: 3,
          name: "Mojtoba Zaman",
          position: "Programmer, UX Design",
          imageUrl: "../assets/teamMemberImages/Zaman_Mantaka.png"
        },
        {
          id: 4,
          name: "Momtazul Arefin",
          position: "Data Engineer",
          imageUrl: "../assets/teamMemberImages/Momtazul_Arefin.png"
        },
        {
          id: 5,
          name: "Ashak Elahi",
          position: "Co-founder 🧢Finance",
          imageUrl: "../assets/teamMemberImages/Ashak_Elahi.png"
        },
        {
          id: 6,
          name: "Chris Nguyen",
          position: "Co-founder 🧢Sale",
          imageUrl: "../assets/teamMemberImages/Chris_Nguyen.png"
        },
        {
          id: 7,
          name: "Anna Sidorenko",
          position: "Business Consultant",
          imageUrl: "../assets/teamMemberImages/Anna_Sidorenko.png"
        },
        {
          id: 8,
          name: "Abdullah Tuhin",
          position: "Business Development Associate",
          imageUrl: "../assets/teamMemberImages/Abdullah_Tuhin"
        }
      ];

      const [isLoading, setIsLoading] = useState(true);

  return (
    <div className='bg-blue-900 h-screen'>
        <div className='p-2 bg-blue-900 flex justify-center items-center'>
          {isLoading && (
            <Lottie animationData={loadingGif} loop style={{ width: 200, height: 200 }} />
          )}
          <img
            src={teamSlide}
            alt=""
            className="w-full max-w-xs mx-auto sm:max-w-2xl my-10 md:max-w-3xl lg:max-w-4xl shadow-xl rounded-md border-4 border-light-blue"
            onLoad={() => setIsLoading(false)}
            style={{ display: isLoading ? 'none' : 'block' }}
          />
        </div>

       {/* <div className="flex flex-col items-start ml-[100px]  mr-10">
        <h1 className='bg-blue-400 bg-opacity-50 text-4xl text-blue-700 font-bold rounded-lg py-[30px] uppercase w-full md:w-[1220px] 2xl:w-[1280px] flex justify-center items-center my-10'>The Team</h1>
        <div className="flex flex-wrap justify-center md:justify-between w-full md:max-w-7xl" >
            
            <div className="w-full md:w-[600px] bg-blue-400 bg-opacity-70 rounded-lg p-5 mb-10">
                <h2 className="text-4xl font-light text-center mb-4 text-blue-700 bg-opacity-50"><span className='font-semibold'>Tech</span> Squad</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-
                2 mb-8">
                {teamMembers.slice(0, 4).map(member => (
                    <TeamCard
                    key={member.id}
                    name={member.name}
                    position={member.position}
                    imageUrl={member.imageUrl}
                    />
                ))}
                </div>
            </div>

            
            <div className="w-full md:w-[600px] bg-blue-400 bg-opacity-70 md:ml-5 rounded-lg p-5 mb-10">
                <h2 className="text-4xl font-light text-center mb-4 text-blue-700 bg-opacity-50"><span className='font-semibold'>Business</span> Squad</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                {teamMembers.slice(4).map(member => (
                    <TeamCard
                    key={member.id}
                    name={member.name}
                    position={member.position}
                    imageUrl={member.imageUrl}
                    />
                ))}
                </div>
            </div>
        </div>
      </div> */}

    </div>
  )
}
