import './App.css';
import RootLayout from './components/RootLayout';
import React from 'react';

import {
  createRoutesFromElements,
  Route,
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import Home from "./components/pages/Home"
import Services from './components/pages/Services';
import OurWork from './components/pages/OurWork';
import About from './components/pages/About';
import Contact from './components/pages/Contact';
import Team from './components/pages/Team';

// import UnderMaintenance from './components/pages/UnderMaintenance';
import { HitCountPage } from './components/pages/HitCountPage';


const router = createBrowserRouter (
  createRoutesFromElements(
    <Route path='/' element={<RootLayout/>}>
      <Route index element={<Home />} />
      <Route path='/services' element={<Services />} />
      <Route path='/our work' element={<OurWork/> } />
      <Route path='/about' element={<About/>}/>
      {/* <Route path='/our_team' element={<Team/>}/> */}
      <Route path='/modelact/hitcount' element={<HitCountPage/>}/>
      <Route path='/contact' element={<Contact/>}></Route>
    </Route>
    
  )
)

//A change is here

function App() {

  return (
    <div className='w-full h-screen'>


        <RouterProvider router={router}/> 


        {/* <UnderMaintenance/> */}
    </div>
  );
}

export default App;
