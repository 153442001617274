import { createSlice } from "@reduxjs/toolkit";

export const serviceSlice = createSlice({
    name: "serviceSlice",
    initialState: { value: {name:"service"} },
    reducers: {
        setServiceScroll: (state,action)=>{
            state.value = action.payload;
        }
    }
})

export const {setServiceScroll}= serviceSlice.actions;

export default serviceSlice.reducer;