import React from 'react'
import content from "../assets/content.json"
import pie_chart from "../assets/pie_chart.json"
import Lottie from 'lottie-react'
import ExpertiseCard from '../ExpertiseCard'

export default function OurWork() {


  return (
    <div id="OUR WORK" className='flex flex-col justify-center items-centermin-h-screen'>
    <div className="w-full h-screen">

      <div className="flex flex-col sm:flex-row bg-blue-900 items-center justify-center">
     
        <div className="relative text-yellow-300 ml-[50px]">
          <h1 className="z-1 text-4xl sm:text-6xl  font-bold flex mb-5">
            Model Act
          </h1>
          <p className="w-[300px] sm:w-[900px] mt-2 font-medium pb-5 text-xl">
            {content.OUR_WORK}
          </p>
        </div>
        <Lottie
          className="sm:w-1/4 sm:h-1/4 w-1/2 h-1/2 relative -z-1 m-auto"
          animationData={pie_chart}
          loop={true}
        />
       
      </div>

      <div className='bg-purple-900'>
      <div className="flex flex-col items-center justify-center sm:grid sm:grid-cols-4">
        {content.OUR_EXPERTISE.Expertise.map((Expertise, idx) => {
              return (
              <ExpertiseCard
                  key={idx}
                  title={Expertise.title}
                  description={Expertise.description}
                  index={idx}
              />
              );
            })}
        </div>
      </div>

    </div>
    </div>
  )
}
