import React, { useEffect, useState } from 'react';
import content from "../assets/content.json";
import { useSelector } from 'react-redux';
import GlassPanel from "../GlassPlane/GlassPanel"; 
import "../GlassPlane/GlassPanel.css"

export default function Services() {
  document.title = "Services";
  const serviceID = useSelector((state) => state.serviceSlice.value);

  const scrollToSection = (sectionId) => {
    // console.log("Section:",sectionId)
    const section = document.getElementById(sectionId);
    console.log(section);
    if (section) {
      section.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  };

  useEffect(() => {
    // console.log("hi",serviceID.name)
    if(serviceID.name === "service"){
      return;
    }
    scrollToSection(serviceID.name);
  }, [serviceID.name]);

  const [panelsVisibility, setPanelsVisibility] = useState(() => {
    const visibility = {};
    Object.keys(content.Services.Services).forEach((serviceName) => {
      visibility[serviceName] = 0;
    });
    return visibility;
  });

  const togglePanelVisibility = (serviceName, index) => {
    setPanelsVisibility(prev => ({
      ...prev,
      [serviceName]: index
    }));
  };

  return (
    <div className="bg-glass-effect">
      <div className="content z-10 relative">
      {Object.keys(content.Services.Services).map((serviceName, idx) => {
        const service = content.Services.Services[serviceName];
        const isReversed = idx % 2 !== 0;

        return (
          <div key={idx} id={`service-${idx}`} className={`flex flex-col md:flex-row items-center ${isReversed ? "md:flex-row-reverse" : ""} py-10 px-4 md:px-10`}>
            <div className="md:flex-1 md:px-5 gradient-text">
              <h1 className='text-3xl md:text-6xl font-bold mb-1'>{serviceName.toUpperCase()}</h1>
              <h2 className='text-xl md:text-2xl font-light italic'>{service.Tagline}</h2>
            </div>
            <GlassPanel className="md:flex-1 relative rounded-lg p-4 md:p-8 my-4 md:mx-4">
              {panelsVisibility[serviceName] === 0 ? (
                <div className="pb-8"> {/* Added padding-bottom to make space for buttons */}
                  <h1 className='text-xl md:text-2xl text-white font-bold mb-1'>Problem</h1>
                  <p className='text-lg md:text-xl text-white mb-5'>{service.Problem}</p>
                  
                  <h5 className='text-xl md:text-2xl text-white font-bold mb-1'>How we can help you</h5>
                  <p className='text-lg text-white md:text-xl'>{service.Example}</p>
                  
                </div>
              ) : (
                <div className="pb-8"> {/* Same padding-bottom here for consistency */}
                  <h3 className='text-2xl md:text-4xl text-white font-bold mb-1'>Benefits</h3>
                  <ul className='list-disc text-white pl-5 text-lg md:text-xl'>
                    {service.Benefits.map((benefit, index) => (
                      <li key={index}>{benefit}</li>
                    ))}
                  </ul>
                </div>
              )}
              <div className='absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2'>
                <button
                  className={`h-6 w-6 rounded-full ${panelsVisibility[serviceName] === 0 ? 'bg-light-blue' : 'bg-gray-400'} flex items-center justify-center font-bold shadow-lg`}
                  onClick={() => togglePanelVisibility(serviceName, 0)}
                  aria-label="Show Service Details"
                />
                <button
                  className={`h-6 w-6 rounded-full ${panelsVisibility[serviceName] === 1 ? 'bg-light-blue' : 'bg-gray-400'} flex items-center justify-center font-bold shadow-lg`}
                  onClick={() => togglePanelVisibility(serviceName, 1)}
                  aria-label="Show Benefits"
                />
              </div>
            </GlassPanel>
          </div>
        );
      })}
    </div>
    </div>
  );
}
