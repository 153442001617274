import React from 'react';
import "../GlassPlane/GlassPanel.css";

const GlassPanel = ({ children }) => {
  return (
    <div className="glass-panel relative bg-transparent shadow-lg">
      {children}
    </div>
  );
};

export default GlassPanel;