import {React,useState } from 'react'
import { HiMiniPresentationChartBar } from "react-icons/hi2";
import { BsGraphUpArrow } from "react-icons/bs";
import { GiBrain } from "react-icons/gi";
import { FaDatabase } from "react-icons/fa";
import { PiGraph } from "react-icons/pi";
import { MdOutlineSettingsSuggest } from "react-icons/md";
import { MdOutlineVerifiedUser } from "react-icons/md";
import { SiGooglebigquery } from "react-icons/si";
import { SiSpringsecurity } from "react-icons/si";
import { Link } from 'react-router-dom';

import { useSelector,useDispatch } from 'react-redux';
import { setServiceScroll } from './state/serviceReducer';

const icons = [
  <HiMiniPresentationChartBar />,
  <BsGraphUpArrow />,
  <GiBrain />,
  <FaDatabase/>,
  <PiGraph/>,
  <MdOutlineSettingsSuggest/>,
  <MdOutlineVerifiedUser/>,
  <SiGooglebigquery/>,
  <SiSpringsecurity/>
]

export default function ServiceCard(props) {
    const { title, desc, index } = props;

    //Getting service redux value from store and defining dispatch Function
    const serviceID = useSelector((state) => state.serviceSlice.value);
    // console.log(serviceID)
    const dispatchService = useDispatch(console.log(serviceID.name))

    //Text size limiting
    const maxLength = 200; // Define the maximum length for the paragraph
    const [content] = useState(desc);
    const [showMore] = useState(content.length > maxLength);

  return (
    <div>
        <Link to="/services">
            <div className='bg-card-blue py-5 2xl:w-[400px] w-[300px] h-[660px] pl-[40px] rounded-lg mr-10' onClick={() => dispatchService(setServiceScroll({ name: `service-${index}` }))}>
                <div className='text-9xl bg-secondary-blue my-10 mr-10 text-white p-10 rounded-lg flex justify-center items-center'>{icons[index]}</div>
                <h5 className="text-xl sm:text-3xl font-bold text-white my-10 pr-5 2xl:pr-0">
                {title}
                 </h5>
                <p to="/services "className="text-xl fond-medium text-white my-10 pr-10" >{showMore ? `${content.slice(0, maxLength)}...` : content}</p>
            </div>
        </Link>
    </div>
  )
}
