import React,{useEffect} from 'react'
import Lottie from "lottie-react";
import data_anim from "../assets/data_anim.json"
import content from "../assets/content.json"
import { NavLink} from 'react-router-dom';
import axios from 'axios';


import ExpertiseCard from '../ExpertiseCard';
import ServiceCard from '../ServiceCard';



export default function Home() {

  document.title = "Model Act";

  const trackVisit = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_DJANGOAPI}/hit_count`, {
        hitData: { count: "1" }
      });
      console.log('Fetched count:', response.data);
    } catch (error) {
      console.error('Error updating hit count:', error);
    }
  };
  

  useEffect(() => {
    if (!sessionStorage.getItem('visited')) {
      trackVisit();
      sessionStorage.setItem('visited', 'true');
    }
  }, []);

  

  return (
    <div className="w-full ">
      <div className="flex bg-blue-900 h-[700px] sm:h-[600px] flex-col sm:flex-row sm:justify-between center items-center rounded-b-[15px] md:rounded-b-[30px]">
  <div className="flex flex-col">
    <div className="relative pl-5 sm:pl-[100px] top-[40px] text-white mb-7 pr-2">
      <h1 className="z-1 text-3xl sm:text-6xl font-bold uppercase">Combining Software</h1>
      <h1 className="z-1 text-3xl sm:text-5xl font-bold mb-3 uppercase">
        and Data Science as a <span className='text-yellow-300'>“Service"</span>
      </h1>
      <p className="font-medium text-xl sm:w-[500px] pt-2">
        Empowering businesses with top-tier data analytics. Our team drives
        innovation with data science, AI, and business intelligence for our
        valued clients.
      </p>
    </div>
    <div className='md:ml-[100px] flex flex-row  justify-center md:justify-start items-center mt-5 md:mb-20'>
      <a href='https://app.modelact.eu/' target='_blank' rel="noopener noreferrer" className=" bg-light-blue rounded-lg text-xl px-3 py-5 my-5 text-white transition-colors duration-300 w-auto flex items-center justify-center">
        Try Our App For Free
      </a>
      <NavLink to={"/contact"} className="ml-5 border-2 border-light-blue rounded-lg text-xl px-3 py-5 my-5 text-white hover:bg-light-blue transition-colors duration-300 w-auto flex items-center justify-center">
        Book A Meeting
      </NavLink>
    </div>
  </div>
  <div className="relative">
    <Lottie
      className="-z-1"
      animationData={data_anim}
      loop={true}
    />
  </div>
</div>


      <div className="bg-white text-dark-blue">
        <div className="flex flex-col justify-center items-center relative sm:px-[50px] pl-7 mt-20 mb-20">
          <h1 className="z-1 text-4xl sm:text-6xl font-bold flex py-5 mt-5 text-secondary-blue">
            Who are we ?
          </h1>
          <p className="py-5 w-auto 2xl:w-[1200px] text-xl">
            As a startup, our core mission is to wholeheartedly serve our
            customers by delivering top-tier data analytics services. Our
            dedicated team of specialists is focused on guiding businesses and
            enterprises in embracing cutting-edge technologies such as data
            science, artificial intelligence, and business intelligence. We
            pledge to consistently provide our utmost effort to assist each of
            our clients to the best of our abilities.
          </p>
          <NavLink to={"/contact"} className=" border-2 border-light-blue rounded-lg text-xl px-3 py-5 my-5 text-light-blue hover:text-secondary-blue hover:bg-light-blue transition-colors duration-300 w-[300px] flex items-center justify-center ">
            Book A Meeting
          </NavLink>
        </div>
      </div>

      <div className="bg-dark-blue rounded-t-[30px] sm:pt-12 flex flex-col 2xl:pl-[100px] px-2 items-center justify-center 2xl:items-start 2xl:justify-start">
        <h1 className="z-1 text-4xl ml-[40px] sm:text-6xl font-bold flex sm:flex-row justify-center items-center flex-col text-white py-5 mt-5">
          Our Services
          </h1>
         <div className='flex items-center justify-start mt-8'>
         <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-8">
          {
              Object.entries(content.Services.Services).slice(0, 4).map(([serviceName, serviceDetails], idx) => {
                // console.log(serviceName)  
                return (
                      <ServiceCard
                          key={idx}
                          title={serviceName}
                          desc={serviceDetails.Example}
                          index={idx}
                      />
                  );
              })
          }
      </div>

        </div> 
        
      </div> 

      {/* <div className="bg-white text-blue-900">
        <div className="flex flex-end sm:pl-[100px] pl-5 flex-col relative">
          <h1 className="z-1 text-6xl font-bold flex py-5 mt-5">MODEL ACT</h1>
          <p className="pt-5 text-2xl sm:w-[1200px] pr-2">{content.OUR_WORK}</p>
          <NavLink
            className="border-2 hover:text-blue-900 border-yellow-300 text-3xl p-5 my-8  hover:bg-yellow-300 transition-colors	duration-300 w-[300px] flex items-center justify-center"
            to="/"
          >
            Learn more
          </NavLink>
        </div>
      </div> */}

      <div className="bg-dark-blue sm:p-12 flex flex-col ">
        <h1 className="z-1 text-4xl sm:text-6xl font-bold flex justify-center items-center flex-col sm:flex-row py-12 ml-4 text-white">
          Our Expertise
        </h1>
        <div className="flex items-center justify-center mt-8">
          <div className="flex flex-col items-center justify-center">
          {content.OUR_EXPERTISE.Expertise.slice(0, 4).map((Expertise, idx) => {
              return (
                  <ExpertiseCard
                      key={idx}
                      title={Expertise.title}
                      desc={Expertise.desc}
                      index={idx}
                  />
              );
          })}
          <a href='https://app.modelact.eu/' target='_blank' rel="noopener noreferrer" className=" bg-light-blue rounded-lg text-xl px-3 py-5 my-5 text-white transition-colors duration-300 w-[300px] sm:w-[501px] flex items-center justify-center">
            Try Our App For Free
          </a>
          <NavLink to={"/contact"} className=" border-2 border-light-blue rounded-lg text-xl px-3 py-5 my-5 text-white hover:bg-light-blue transition-colors duration-300 w-[300px] sm:w-[501px] flex items-center justify-center">
            Book A Meeting
          </NavLink>

          </div>
        </div>
      </div>
    </div>
  );
}
