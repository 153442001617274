import {React, useState} from 'react'

import Lottie from "lottie-react";
import contactAnim from "./assets/contactUs.json";

import AlertPopup from './AlertPopup'

export default function ContactForm() {

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });

    const [formErrors, setFormErrors] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
      });

    const [showAlert, setShowAlert] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value
        });
      
        // Clear error message when the user starts typing in the field
        setFormErrors({
          ...formErrors,
          [name]: ''
        });
      };
    
    // Function to handle form submission
    const handleSubmit = (e) => {
      e.preventDefault();
      const errors = validateForm();
    
      if (Object.keys(errors).length === 0) {
        const config = {
          SecureToken: "d209dfc0-49b9-4bdc-9ce3-31bef2794e73",
          To: "aminul.didar@proton.me",
          From: "mantaka35@gmail.com",
          Subject: `${formData.name} has a massage for Model Act`,
          Body: `Name: ${formData.name} \n Email: ${formData.email} \n Phone: ${formData.phone} \n \n Message: ${formData.message}`,
        };
    
        if (window.Email) {
          window.Email.send(config)
            .then(() => {
              console.log('Email sent successfully:', formData);
              setShowAlert(true);
              
              setFormData({
                name: '',
                email: '',
                phone: '',
                message: ''
            });
            })
            .catch((error) => {
              console.error('Error sending email:', error);
              // Handle the error, such as displaying an error message to the user
            });
        }
        
      } else {
        setFormErrors(errors);
      }
    };

    

     const validateForm = () => {
      const errors = {};

      if (!formData.name.trim()) {
        errors.name = 'Name is required';
      }

      // Regular expression for email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!formData.email.trim()) {
        errors.email = 'Email is required';
      } else if (!emailRegex.test(formData.email.trim())) {
        errors.email = 'Invalid email format';
      }

      if (!formData.message.trim()) {
        errors.message = 'Message is required';
      } else if (formData.message.trim().length < 5) {
        errors.message = 'Message should be at least 25 characters';
      }

      return errors;
  };

      
      

  return (
    <div className='flex flex-col xl:flex-row mt-20 xl:w-[1200px] w-auto'>
        <div className=' bg-secondary-blue flex flex-col items-center  xl:rounded-l-[30px] xl:mb-20 xl:w-[400px]'>
            <h1 className='md:text-6xl text-3xl mb-7 text-white font-bold pt-20' >CONTACT US</h1>
            <Lottie
            className='md:text-6xl text-3xl'
            animationData={contactAnim}
            loop={true}
            />
        </div>
        <div>
            <div className='bg-dark-blue p-12 xl:rounded-tr-[30px]'>
                <h1 className='md:text-6xl text-3xl font-bold mb-5 pt-5 text-white'>LET <span className='text-light-blue'>US</span> GET IN TOUCH</h1>
                <p className='text-2xl font-medium md:w-[500px] text-light-blue'>Submit what you want to know, and our experts will get back to you very soon.</p>
            </div>
            <form onSubmit={handleSubmit} className='grid grid-cols-2 gap-8 p-5 bg-dark-blue xl:rounded-br-[30px] h-[500px] mb-20'>
                <div className='flex flex-col'>
                    <label className='md:text-2xl py-4 font-semibold text-white'>Name <span className="text-red-500">{formErrors.name && '*'}</span></label>
                    <input className='border-b-4 border-0 border-light-blue' name="name" type="text" value={formData.name}
                     onChange={handleInputChange}>
                    </input>
                    <p className="text-red-500">{formErrors.name}</p>
                    

                    <label className='md:text-2xl py-4 font-semibold text-white'>Email <span className="text-red-500">{formErrors.email && '*'}</span></label>
                    <input className='border-b-4 border-0 border-light-blue' name="email" type="text"
                    value={formData.email}
                    onChange={handleInputChange}
                    >
                    </input>
                    <p className="text-red-500">{formErrors.email}</p>

                    <label className='md:text-2xl py-4 font-semibold text-white'>Phone Number</label>
                    <input className='border-b-4 border-0 border-light-blue' name="phone" type="tel"
                    value={formData.phone}
                    onChange={handleInputChange}
                    >
                    </input>
                    
                    

                </div>
                <div className='flex flex-col'>
                    <label className='md:text-2xl py-2 font-semibold text-white'>How can we help you? <span className="text-red-500">{formErrors.message && '*'}</span></label>
                    <textarea
                        className='border-4  border-light-blue h-[200px]'
                        name="message"
                        placeholder='Enter message here.'
                        value={formData.message} // Change from defaultValue to value
                        onChange={handleInputChange}
                    ></textarea>
                    <p className="text-red-500">{formErrors.message}</p>

                    <input className='border-2 font-semibold border-light-blue text-xl text-white p-2 my-8 hover:bg-light-blue transition-colors duration-300 md:w-[250px] flex items-center justify-center hover:cursor-pointer' type="submit" value="SUBMIT"/>
                </div>
                
            </form>
        </div>
        <div className="p-5">
          {showAlert && (
            <AlertPopup
              type="success"
              message="Your operation was successful!"
              onClose={() => setShowAlert(false)}
            />
          )}
        </div>
    </div>
  )
}