import React from 'react';
import { GiBrainstorm } from "react-icons/gi";
import { TbDatabaseSearch } from "react-icons/tb";
import { SiGooglebigquery } from "react-icons/si";
import { PiPottedPlantLight } from "react-icons/pi";

const icons = [
    <GiBrainstorm className='h-full w-full text-6xl sm:text-9xl text-light-blue p-5' />,
    <TbDatabaseSearch className='h-full w-full text-6xl sm:text-9xl text-light-blue p-5' />,
    <SiGooglebigquery className='h-full w-full text-6xl sm:text-9xl text-light-blue p-5' />,
    <PiPottedPlantLight className='h-full w-full text-6xl sm:text-9xl text-light-blue p-5' />
];

export default function ExpertiseCard(props) {
    const { title, desc, index } = props;

    const isReversed = index % 2 !== 0; // Determines the ordering of content

    return (
      <div className={`flex flex-col sm:flex-row ${isReversed ? 'sm:flex-row-reverse' : ''} items-center w-full overflow-hidden`}>
        <div className="flex-1 px-4 sm:px-8 py-5">
            <h2 className="text-3xl sm:text-4xl font-semibold mb-3 sm:mb-5 text-white">{title}</h2>
            <ul className="list-disc list-inside text-white font-thin text-md sm:text-lg pl-5">
                {desc.map((skill, idx) => (
                    <li key={idx} className="my-2 sm:my-3">{skill}</li>
                ))}
            </ul>
        </div>
        <div className="flex-none bg-secondary-blue rounded-3xl m-5 sm:m-5 w-[350px] sm:w-[475px] h-[300px] sm:h-[371px] flex items-center justify-center mb-10 sm:mb-20">
            {icons[index % icons.length]}
        </div>
    </div>
    );
}
