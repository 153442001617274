import React, { useEffect, useState } from 'react';
import axios from 'axios';

export const HitCountPage = () => {
    const [hitCount, setHitCount] = useState('Loading...');

    const fetchHitCount = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_DJANGOAPI}/hit_count`, {
                hitData: { count: "0" }  // Sending "0" to fetch without incrementing
            });
            console.log('Fetched count:', response.data);
            setHitCount(response.data);  
        } catch (error) {
            console.error('Error fetching hit count:', error);
            setHitCount('Error fetching count');
        }
    };

    useEffect(() => {
        fetchHitCount();
    }, []);

    return (
        <div>
            <h1>Hit Count: {hitCount}</h1>
        </div>
    );
};
