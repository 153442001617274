import React from 'react';
import logo from "./assets/companyLogo/ModelActLogoBlue.png"

export default function Footer() {


  const footerLinks = {
    "Product & Services": [
      {name: "Model Act", url:"https://app.modelact.eu/"},
      { name: "Big Data Analytics", url: "/services" },
      { name: "Predictive Analytics", url: "/services" },
      { name: "Machine Learning Analysis", url: "/services" },
      {name: "Statistical Analysis", url: "/services"}
    ],
    Technologies: [
      { name: "TensorFlow", url: "https://www.tensorflow.org" },
      {name: "PyTorch", url: "https://pytorch.org/"},
      { name: "Scikit-Learn", url: "https://scikit-learn.org" },
      { name: "React", url: "https://reactjs.org" },
      { name: "Django", url: "https://www.djangoproject.com" },
    ],
    Company: [
      { name: "Contact Us", url: "/contact" },
      // { name: "Team", url: "/our_team" },
      { name: "Privacy Policy", url: "/privacy" },
      { name: "Terms of Service", url: "/terms" }
    ],
};
  

  return (
    <footer className="bg-dark-blue border-t-4 border-light-blue text-white p-10">
      <div className="flex flex-wrap justify-between items-start">
        <div className="w-full sm:w-auto mb-6 sm:mb-0">
          <img src={logo} alt="Company Logo" className="h-[100px] pl-10" />
          <h1 className='text-secondary-blue text-4xl font-bold p-2'>Model Act</h1>
        </div>
        {Object.entries(footerLinks).map(([category, links]) => (
          <div key={category} className="w-1/2 sm:w-auto mb-6 sm:mb-0 text-secondary-blue">
            <h5 className="font-bold mb-2">{category}</h5>
            <ul>
              {links.map(link => (
                <li key={link.name}>
                  <a href={link.url} className="text-secondary-blue transition-all duration-150 hover:scale-110">{link.name}</a>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </footer>
  );
}
