import React from 'react'
import ContactForm from "../ContactForm"

import { MdLocationPin } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { BiLogoGmail } from "react-icons/bi";

function Contact() {
  document.title = "Contact";

  return (
    <div className='flex flex-col justify-center items-center bg-white min-h-screen'>

        <ContactForm/>

        <h1 className='md:text-6xl text-3xl px-4 flex justify-center items-center py-20 font-bold bg-dark-blue text-white rounded-t-[30px] w-full'>You are welcomed for any of your queries</h1>

        <div className='flex justify-center items-center flex-col xl:grid xl:grid-cols-2 gap-4 bg-dark-blue w-full pb-10'>
          <div className='bg-dark-blue w-full flex flex-end sm:pl-[100px] pl-5 flex-col relative'>
            <h1 className='text-6xl font-bold my-5 py-2 border-b-8 border-light-blue text-white w-[350px]'>Head Office</h1>
              <div className='flex py-5 text-3xl font-bold'>
                <img className='w-[50px] h-[30px] mr-5' alt="" src='https://imgs.search.brave.com/C7OGtD8ffjxUfiS6VgRobNSkppRW2vlwapnLBWmlg64/rs:fit:500:0:0/g:ce/aHR0cHM6Ly90NC5m/dGNkbi5uZXQvanBn/LzAxLzA1LzAwLzQ5/LzM2MF9GXzEwNTAw/NDk4Nl83NVplVGhY/a2Ewcnd0dHJKRnRN/Z2d6aER0VjZ6MEU5/eC5qcGc'/>
                <h1 className='text-white'>FINLAND</h1>
              </div>
              <div className='flex py-5 text-3xl font-semibold'>
                <MdLocationPin className=' text-4xl mr-5 text-light-blue' />
                <h1 className=' text-white'>53850 LAPPEENRANTA</h1>
              </div>
              <div>
                <div className='flex py-5 text-3xl font-semibold'>
                  <BiLogoGmail className=' text-4xl mr-5 text-light-blue'/>
                  <h1 className=' text-white'>info@modelact.eu</h1>
                </div>
              </div>
              <div className='flex py-5 text-3xl font-semibold'>
                <FaPhoneAlt className=' text-4xl mr-5 text-light-blue'/>
                <h1 className='text-white'>+358 417255770</h1>
              </div>
          </div>

          
          <iframe 
          className='my-2'
          title="Google Map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d123573.8746673946!2d27.847832408975055!3d61.05680302992987!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4690935c25d5652d%3A0x1c00b55478585380!2s53850%20Lappeenranta%2C%20Finland!5e0!3m2!1sen!2sbd!4v1713190887921!5m2!1sen!2sbd" 
          width="600" 
          height="450" 
          style={{ border: "6px solid #00B8FF", borderRadius: "3px"}}
          allowfullscreen="" 
          loading="lazy" 
          referrerpolicy="no-referrer-when-downgrade">
          </iframe>

        </div>
        

    </div>
  )
}

export default Contact