import React, { useState} from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import { setServiceScroll } from './state/serviceReducer';

import ModelActLogo from './assets/companyLogo/ModelActLogo.png';
// import ModelActText from "./assets/companyLogo/ModelActText.png"
import Home from './pages/Home';

export default function Navbar() {
  const links = [
    { name: 'HOME', link: '/' },
    { name: 'SERVICES', link: '/services' },
    //{ name: 'OUR WORK', link: '/our work' },
    { name: 'ABOUT', link: '/about' },
    // { name: 'TEAM', link: '/our_team' },
    // { name: 'CONTACT', link: '/contact' },
  ];

  const [open, setOpen] = useState(false);

  const serviceID = useSelector((state) => state.serviceSlice.value);

  const dispatchService = useDispatch(console.log(serviceID.name))
  

  return (
    <div className='shadow-lg w-full sticky top-0 left-0 z-10 '>
      <div className='lg:flex lg:justify-between items-center h-[90px] bg-dark-blue lg:px-10 px-7 myNav'>
      <div className='lg:flex lg:flex-row'>
      <div className='flex justify-center items-center'>
      <NavLink to={links[Home]} className=' flex items-center '>
        {/* Model Act */}
        <img className='h-8 w-14 mr-4' src={ModelActLogo} alt="Model Act" />
        {/* <img className='hidden sm:block opacity-0 sm:opacity-100 sm:h-auto sm:w-auto' src={ModelActText} alt="" /> */}
        <h1 className='text-white text-2xl font-bold p-2'>Model Act</h1>
      </NavLink>

        <div
          onClick={() => setOpen(!open)}
          className='text-3xl absolute right-8 top-6 cursor-pointer lg:hidden'
        >
          <ion-icon name={open ? 'close' : 'menu'}></ion-icon>
        </div>
        </div>
        <ul
          className={`lg:flex lg:items-center lg:pb-0 pb-12 absolute lg:static bg-dark-blue font-semibold
          lg:z-auto z-10 left-0 w-full lg:w-auto lg:pl-0 pl-9 transition-all duration-500 ease-in lg:overflow-hidden ${
            open ? 'top-20 pt-4' : 'top-[-560px]'
          }`}
        >
          {links.map((link, idx) => {
            return (
              <li
                key={idx}
              >
                <NavLink
                  onClick={link.name === "SERVICES" ? () => dispatchService(setServiceScroll({ name: "service" })) : null}
                  to={link.link}
                  className={({ isActive }) =>
                    "w-full sm:w-auto text-center text-white duration-150 block transition-all transform  sm:hover:bg-secondary-blue hover:rounded-lg  hover:scale-110 lg:ml-4 lg:mr-4 text-lg lg:my-2 py-2 px-2" +
                    (isActive ? " sm:bg-secondary-blue sm:p-4 rounded-lg hover:scale-110 transition-all duration-150" : "")
                  }
                  style={{ minWidth: "100px" }} // Ensure a minimum width for all links
                >
                  {link.name}
                </NavLink>
              </li>
            );
          })}
          </ul>
          </div>
          
          <ul className={`flex flex-col justify-center items-center lg:pb-0 pb-12 absolute lg:static bg-dark-blue font-semibold
          lg:z-auto z-10 left-0 w-full lg:w-auto lg:pl-0 pl-9 transition-all duration-500 ease-in lg:overflow-hidden ${
            open ? 'top-[250px] pt-4' : 'top-[-560px]'
          }`}>
          <li className='flex'>
            <a
              href="https://app.modelact.eu/"
              className="border-2 m-2 border-light-blue rounded-lg text-xl text-white p-3 hover:bg-light-blue w-[100px] flex items-center justify-center transition-all hover:scale-110 duration-150"
              target="_blank"
              rel="noopener noreferrer"
            >
              Apps
            </a>
            <NavLink
              to={'/contact'}
              className={({ isActive }) => 
            "border-2 m-2 border-light-blue rounded-lg text-xl p-3 w-[100px] flex items-center justify-center transition-all duration-150 " + 
            (isActive ? "text-white bg-light-blue hover:scale-110" : "text-white hover:bg-light-blue hover:scale-110")
  }
              rel="noopener noreferrer"
            >
              Contact
            </NavLink>
          </li>
          </ul>
        

      </div>
    </div>
  );
}
