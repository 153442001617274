import React from 'react'
import teamAnim from "../assets/team_anim.json"
import Lottie from 'lottie-react'

import content from "../assets/content.json"

import { HiOutlineLightBulb } from "react-icons/hi";
import { TbTargetArrow } from "react-icons/tb";



export default function About() {
  document.title = "About";

  return (
       <div id="ABOUT" className='static min-h-screen bg-dark-blue'>

        <div className='flex bg-white flex-col sm:justify-between items-center px-5 sm:pl-[100px] pb-12 rounded-b-[15px] md:rounded-b-[30px]'>
            <h1 className="text-4xl sm:text-6xl font-bold py-5 mt-5 text-secondary-blue">
                About Us
            </h1>
            <div className='text-card-blue flex flex-col xl:flex-row justify-center items-center'>
                <p className='xl:w-[1000px] w-auto mt-4 text-2xl'>{content.ABOUT_US.Who_are_we}</p>
                <Lottie className='px-[50px]' animationData={teamAnim} loop={true} />
            </div>
        </div>

        
        <div className='flex flex-col justify-center sm:items-start items-center sm:px-[100px] sm:pb-[10px]'>
          <div className='flex flex-row justify-center items-end'>
          <h1 className='flex text-4xl sm:text-6xl font-bold text-white mb-5 mt-12'>OUR VISION</h1>
          <HiOutlineLightBulb className='text-8xl mx-5 pt-5 transform rotate-45 text-light-blue' />
          </div>
          <p className='text-white w-auto xl:w-[1200px] p-5 text-2xl bg-secondary-blue rounded-[30px]'>{content.ABOUT_US.Vision}</p>
        </div>

        <div className='flex flex-col justify-center items-center sm:items-end sm:px-[100px] sm:pb-[100px] w-full'>
        <div className='flex flex-row justify-center sm:justify-end items-end w-full pr-0'>
            <h1 className='text-4xl sm:text-6xl font-bold text-white mb-5 mt-12'>OUR MISSION</h1>
            <TbTargetArrow className='text-8xl pt-5 transform rotate-90 text-light-blue' />
        </div>
        <p className='text-white w-auto xl:w-[1200px] p-5 text-2xl bg-secondary-blue rounded-[30px]'>
            {content.ABOUT_US.MISSION}
        </p>
        </div>

       
       
        
      </div>
   
  )
}
